import { UseReportsProps } from '../types/Reports.types';

export const initialValues: UseReportsProps = {
  openDownloadModal: false,
  openScheduleModal: false,
  openScheduledModal: false,
  fileType: '', // file type e.g. .csv
  emailAddress: '', // email where the report will be sent
  creditCardData: [], // result order created On filter
  filter: {}, // Define a type for the filter object
};
