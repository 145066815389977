import { Grid } from '@hexa-ui/components';
import { DollarSign } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import { Router } from '../../Router';

import { StyledGrid } from './AppBarConfigProvider.styles';

const DollarSignIcon = () => <DollarSign />;

const AppBarConfigProvider = (): JSX.Element => {
  // const Grid2Icon = () => <Grid2 />;

  // const intl = useIntl();

  useSidebar({
    items: [
      {
        id: '1',
        title: 'Payments',
        icon: DollarSignIcon,
        path: '/bees-gateway-web',
      },
    ],
    utils: [],
  });

  return (
    <StyledGrid type="fluid" sidebar>
      <Router />
      <Grid.Item xl={9} lg={9} md={9} />
      <Grid.Item xl={3} lg={3} md={3}>
        {/* <section style={{ margin: '5rem 0', width: '100%' }}>{<ThemeSelection />}</section> */}
      </Grid.Item>
    </StyledGrid>
  );
};

export default AppBarConfigProvider;
