import { useQuery } from '@tanstack/react-query';

import { GraphQLClient } from 'graphql-request';
import { IFetch } from './useFetch.types';

const graphQlClient = (
  apiUrl: string,
  authorization: string,
  country?: string,
  requestTraceId?: string,
  authorizationHub?: string
) => {
  return new GraphQLClient(apiUrl, {
    headers: {
      Authorization: authorization,
      country,
      requestTraceId,
      authorizationHub: authorizationHub,
    },
  });
};

export function useFetch({
  key,
  graph,
  query,
  settings,
  variables,
  authorization,
  country,
  requestTraceId,
  authorizationHub,
}: IFetch) {
  return useQuery({
    keepPreviousData: settings?.keepPreviousData ?? false,
    staleTime: settings?.staleTime ?? 5000,
    enabled: settings?.enabled ?? true,
    cacheTime: settings?.cacheTime ?? 300000,
    retry: 3,
    refetchOnWindowFocus: false,
    queryKey: key,
    queryFn: async () => {
      if (!country) {
        return null;
      }
      return await graphQlClient(
        graph,
        authorization,
        country,
        requestTraceId,
        authorizationHub
      ).request(query, variables);
    },
  });
}
